<template>
    <div>
        <div class="m-2">
            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                    <label>Show</label>
                    <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                        @input="filterTable"
                    />
                    <label>entries</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="9" class="mobile_tab_max_width_flex">
                    <div class="d-flex align-items-center justify-content-end mobile-view mr_1_last_child mobile_tab_display_block">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem" placeholder="Search..." @input="filterTable"/>

                        <b-button
                          v-if="(siteData != null && siteData._id != 'all-site') && (tab_status == 'open')"
                          variant="secondary"
                          class="mr-1 mobile-margin-bottom"
                          @click="openRedoModel"
                          :disabled="!(selected.length > 0)"
                        >
                          <span class="text-nowrap">Failed</span>
                        </b-button>
                        
                        <!-- <b-button :disabled="!(selected.length > 0)" v-if="siteData != null && siteData._id != 'all-site' && tab_status != 'validate' && tab_status != 'failed'" variant="warning" class="mr-1 mobile-margin-bottom" @click="openAssignCrewModel">
                            <span class="text-nowrap">Assign Crew</span>
                        </b-button> -->

                        <b-button :disabled="!(selected.length > 0)" variant="success" class="mr-1 mobile-margin-bottom" @click="closeTask" v-if="checkPermission($route.name, 'Validate All') && (tab_status == 'validate' || tab_status == 'all')">
                            <span class="text-nowrap">Close Task</span>
                        </b-button>

                        <b-button :disabled="!(selected.length > 0)" variant="danger" class="mr-1 mobile-margin-bottom" @click="changeMultipleStatus('deleted')" v-if="checkPermission($route.name, 'Delete')">
                            <span class="text-nowrap">Delete</span>
                        </b-button>

                        <b-button v-if="checkPermission($route.name, 'Add')" variant="primary" :to="{name:'add-defects'}" class="mr-1 mobile-margin-bottom">
                            <span class="text-nowrap">Add</span>
                        </b-button>
                    </div>
                </b-col>

            </b-row>
        </div>
        <b-table sticky-header
            ref="refUserListTable"
            class="position-relative  mTable common_big_tbl"
            :items="dataList"
            responsive
            :fields="getTableColumns()"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
        >
            <!-- Column: User -->
            <template #head(checkbox)="items">
                <b-form-checkbox class="custom-control-warning p-0" v-model="selectAll" @change="selectall"/>
            </template>

            <template #cell(checkbox)="items">				
                <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />				
            </template>	
			
			      <template #cell(info)="items">             
                <feather-icon
                  :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon' "
                  @click="items.toggleDetails"
                />
            </template>

            <template #cell(site)="items">				
                <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>				
            </template>

            <template #cell(locations)="items">
                <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
            </template>

            <template #cell(unique_id)="items">				
                <span @click="items.toggleDetails" class="wordBreak">{{ items.item.unique_id}}</span>				
            </template>

            <template #cell(title)="items">				
                <span @click="items.toggleDetails" class="wordBreak">{{ items.item.title | capitalize}}</span>				
            </template>

            <template #cell(crew)="items">
                <span class="wordBreak">{{ items.item.crew | crewArray }}</span>				
            </template>

            <template #cell(feedback)="items">				
                <!-- <span class="wordBreak" v-if="items.item.feedback_from == 'web'">Web</span> -->
                <span class="wordBreak" v-if="items.item.feedback_from == 'web'">QR Code</span>
                <span class="wordBreak" v-else-if="items.item.feedback_from == 'app'">App</span>
                <span class="wordBreak" v-else>{{ 'N/A'  }}</span>
            </template>

            <template #cell(start)="items">
                <!-- <span v-if="siteData" class="wordBreak">{{ sitedateTime(items.item.start,siteData.pdf_date_format,'' )}}</span> -->
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{ sitedateTime(items.item.start,date_format,'' )}}</span>
            </template>

            <template #cell(end)="items">
                <!-- <span v-if="siteData" class="wordBreak">{{ sitedateTime(items.item.end,siteData.pdf_date_format,'' )}}</span> -->
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{ sitedateTime(items.item.end,date_format,'' )}}</span>
            </template>

            <template #cell(completed_by)="items">
                <span @click="items.toggleDetails" class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                <span @click="items.toggleDetails" v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
            </template>
            
            <template #cell(status)="items">
                <b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize">
                    {{ items.item.status | capitalize}}
                </b-badge>				
            </template>

            <template #cell(task_status)="items">
                <b-badge pill :variant="variantProgressColor(items.item.task_status)" class="text-capitalize mb-2" @click="items.toggleDetails">
                    {{ items.item.task_status | capitalize}}
                </b-badge>				
            </template>

            <!-- <template #cell(actual_started)="items">                 -->
                <!-- <span v-if="siteData">{{sitedateTime(items.item.actual_started,siteData.pdf_date_format,siteData.pdf_time_format)}}</span> -->
                <!-- <span v-if="siteData">{{sitedateTime(items.item.actual_started,date_format,time_format)}}</span> -->
            <!-- </template> -->

            <template #cell(actual_end)="items">                
                <!-- <span v-if="siteData">{{sitedateTime(items.item.actual_end,siteData.pdf_date_format,siteData.pdf_time_format)}}</span> -->
                <span @click="items.toggleDetails" v-if="siteData">{{sitedateTime(items.item.actual_end,date_format,time_format)}}</span>
            </template>

            <template #cell(created_at)="items">				
                <!-- <span v-if="siteData" class="wordBreak">{{sitedateTime(items.item.created_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span> -->
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{sitedateTime(items.item.created_at,date_format,time_format)}}</span>
            </template>

            <template #cell(total_time)="items">				
                <span class="wordBreak">{{items.item.total_time | capitalize}}</span>
            </template>

            <template #cell(actions)="items">
                <div>
                    <!-- <b-link v-b-tooltip.hover.v-warning title="Edit" variant="outline-warning" :to="{ name: 'edit-defect', params: { id: items.item._id } }">
                        <feather-icon icon="Edit2Icon" class="mediumSize" />	
                    </b-link> -->
                    
                    <!-- <b-link v-b-tooltip.hover.v-warning title="Update" variant="outline-warning" @click="view(items)">
                        <feather-icon icon="EditIcon" class="mediumSize ml-1" />	
                    </b-link>
                    
                    <b-link v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning" @click="deleteItem(items.item._id)">
                        <feather-icon icon="Trash2Icon" class="mediumSize ml-1" />	
                    </b-link> -->

					          <b-link v-b-tooltip.hover.v-warning title="Edit" variant="outline-warning" :to="{ name: 'edit-defects', params: { id: items.item._id } }" v-if="(checkPermission($route.name, 'Edit')) && (tab_status == 'open')">
                        <feather-icon icon="EditIcon" class="mediumSize mr-1" />    
                    </b-link>
					          
                    <b-link v-b-tooltip.hover.v-warning title="Start" variant="outline-warning" @click="updateTask('in_progress',items.item)" v-if="checkPermission($route.name, 'Update') && (items.item.task_status == 'open' && items.item.start <= current_date && ['site_client','site_manager','admin','administrator'].indexOf($store.getters.currentUser.role) >= 0 )">
                        <feather-icon icon="CheckSquareIcon" class="mediumSize mr-1" />    
                    </b-link>

                    <b-link v-b-tooltip.hover.v-warning title="Update" variant="outline-warning" @click="view(items)" v-if="checkPermission($route.name, 'Update') && ((items.item.task_status == 'in_progress' && items.item.start <= current_date && ['site_client','site_manager','admin','administrator'].indexOf($store.getters.currentUser.role) >= 0) ||  (items.item.task_status == 'validate' && items.item.start <= current_date && ['site_client','site_manager','admin','administrator'].indexOf($store.getters.currentUser.role) >= 0))">
                        <feather-icon icon="EditIcon" class="mediumSize mr-1" />	
                    </b-link>

                    
                    <b-link v-if="(checkPermission($route.name, 'Download Report')) && (items.item.task_status == 'closed' && items.item.pdf != null)" @click="openPdf(items.item.pdf)" v-b-tooltip.hover.v-warning title="Download Report" variant="outline-warning">
                        <feather-icon icon="DownloadIcon" class="mediumSize mr-1" />    
                    </b-link>

                    <b-link v-b-tooltip.hover.v-warning title="Mark Failed" variant="outline-warning" @click="openFailedPopup(items.item)" v-if="checkPermission($route.name, 'Update') && (items.item.task_status == 'open' && ['site_client','site_manager','supervisor','admin','administrator'].indexOf($store.getters.currentUser.role) >= 0)">
                        <feather-icon icon="XIcon" class="mediumSize mr-1" />    
                    </b-link>


                    <b-link v-b-tooltip.hover.v-warning title="Restart" variant="outline-warning" @click="updateTask('open',items.item)" v-if="checkPermission($route.name, 'Update') && (items.item.task_status == 'failed' && ['site_client','site_manager','supervisor','admin','administrator'].indexOf($store.getters.currentUser.role) >= 0)">
                        <feather-icon icon="CornerUpLeftIcon" class="mediumSize mr-1" />    
                    </b-link>
                    
                    <b-link v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning" @click="deleteItem(items.item._id)" v-if="checkPermission($route.name, 'Delete') && items.item.task_status != 'closed'">
                        <feather-icon icon="Trash2Icon" class="mediumSize mr-1" />	
                    </b-link>

                    <b-link v-if="(checkPermission($route.name, 'Re Generate Report')) && ((items.item.task_status == 'closed') && (tab_status == 'all'))" @click="reGenerateReport(items.item._id)" v-b-tooltip.hover.v-warning title="Re Generate Report" variant="outline-warning">
                        <feather-icon icon="RefreshCwIcon" class="mediumSize mr-1" />
                    </b-link>

                </div>
            </template>

			<template #row-details="items">
                <div class="p-1 px-2 fbDetail mb-1">
                    <b-row>

                        <b-col cols="12" xl="12" v-if="(tab_status == 'open' || tab_status == 'failed' || tab_status == 'all')">
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Project Site 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ items.item.site.site_name | capitalize}}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Location 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Start Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.start,date_format,'' ) }}
                                    </td>

                                  </tr>

                                  <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            End Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.end,date_format,'' ) }}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Completed By 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                                        <span v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Time Taken 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.total_time | capitalize }}</span>
                                    </td>

                                    
                                </tr>
                                <tr>
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Start Time
                                          </h6>
                                       
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="siteData">{{sitedateTime(items.item.actual_started,date_format,time_format)}}</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <b-button
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-secondary"
                                          @click="items.toggleDetails"
                                          size="sm"
                                          class="ml-1"
                                        >
                                          Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                </tr>
                            </table>
                        </b-col>


                        <b-col cols="12" xl="12" v-if="tab_status == 'in_progress'">
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Project Site 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ items.item.site.site_name | capitalize}}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Location 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
                                    </td>
                                
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Start Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.start,date_format,'' ) }}
                                    </td>

                                  </tr>

                                  <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            End Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.end,date_format,'' ) }}
                                    </td>
                                    
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Completed By 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                                        <span v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Time Taken 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.total_time | capitalize }}</span>
                                    </td>

                                    
                                </tr>
                                <tr>
                                      <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Start Time 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="siteData">{{sitedateTime(items.item.actual_started,date_format,time_format)}}</span>
                                    </td>
                                    </tr>
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <b-button
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-secondary"
                                          @click="items.toggleDetails"
                                          size="sm"
                                          class="ml-1"
                                        >
                                          Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                </tr>
                            </table>
                        </b-col>

                        <b-col cols="12" xl="12" v-if="tab_status == 'validate'">
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Project Site 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ items.item.site.site_name | capitalize}}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Location 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Start Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.start,date_format,'' ) }}
                                    </td>

                                  </tr>

                                  <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            End Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.end,date_format,'' ) }}
                                    </td>
                                    
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Completed By 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                                        <span v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Time Taken 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.total_time | capitalize }}</span>
                                    </td>
                                </tr>
                                
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <b-button
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-secondary"
                                          @click="items.toggleDetails"
                                          size="sm"
                                          class="ml-1"
                                        >
                                          Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                </tr>
                            </table>
                        </b-col>

                    </b-row>
                </div>

            </template>

        </b-table>

        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end pagination_fix">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18"/>
                        </template>

                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18"/>
                        </template>
                    </b-pagination>
                </b-col>

            </b-row>
        </div>

		<!-- assign crew modal -->
        <b-modal
			id="assign_crew"
			ref="assign_crew"
			cancel-variant="outline-secondary"
			ok-variant="outline-warning"
			ok-title="Submit"
			cancel-title="Close"
			centered
			title="Assign Crew"
			@ok="updateCrew"
			no-close-on-backdrop
		>
			<b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
				<div class="alert-body">
					{{error_message}}
				</div>
			</b-alert>

			<b-form>

				<b-row>
					<b-col cols="12" md="12" class="mb-md-0 mb-2">
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="allAssignCrews"
							label="full_name"
							class="w-100"
							multiple
							placeholder="Select"
							v-model="crew"
						/>
					</b-col>
				</b-row>

			</b-form>

		</b-modal>

		<!-- failed task modal -->
		<b-modal
            id="failed_tasks"
            ref="failed_tasks"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Mark Task Failed"
            @ok="markTaskFailed"
            no-close-on-backdrop
        >
            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body">
                    {{error_message}}
                </div>
            </b-alert>

            <b-form>

                <b-row>
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                        <b-form-group label="Reason" class="required">
                            <b-form-textarea placeholder="" rows="3" v-model="remarks" />
                        </b-form-group>
                    </b-col>
                </b-row>

            </b-form>

        </b-modal>

        <b-modal
          id="redo-modal"
          ref="redo-modal"
          cancel-variant="outline-secondary"
          ok-variant="outline-warning"
          ok-title="Submit"
          cancel-title="Close"
          centered
          title="Failed Task Comment"
          @ok="redoTasks"
          no-close-on-backdrop
        >

          <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1" >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>

          <b-form>
            <b-row>
              <b-col cols="12" md="12" class="mb-md-0 mb-2">
                <b-form-group label="Comment" class="required">
                  <b-form-textarea placeholder="Type Comment" rows="3" v-model="redo_comment"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

        </b-modal>
    </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, 
  BDropdownItem, BPagination, BCardBody, BFormCheckbox, BAlert, BForm, BBreadcrumb, BFormDatepicker,
  BTabs, BTab, BFormSelect, BFormSelectOption, VBTooltip, BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import Completed from './Completed.vue';
import Bus from "../../../event-bus";
import Ripple from 'vue-ripple-directive'

export default {
	components: {
		BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown,
		BDropdownItem, BPagination, BFormCheckbox, vSelect, BAlert, BForm, BBreadcrumb, BFormDatepicker,
		BTabs, BTab, BFormSelect, BFormSelectOption, Completed, BFormTextarea
	},
	directives: {
		'b-tooltip': VBTooltip, Ripple
	},
    props: ['siteData', 'locations', 'crew_filter', 'status', 'start', 'end', 'tab_status'],
    watch: { 
        siteData: function(newVal, oldVal) { // watch it
            this.filterTable();
            this.updateDateFormat();
        },
        locations: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        crew_filter: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        status: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        start: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        end: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        tab_status: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
    },

	data() {
		return {
			date_format : 'DD MMM YYYY',
			time_format : 'HH:mm',
		    tableColumns : [
				/* { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '2%',fontSize:'10px'}},
				{ key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '8%',fontSize:'10px'}},
				{ key: 'locations', label: 'Location', sortable: false , thStyle:  {width: '7%',fontSize:'10px'}},
				{ key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '6%',fontSize:'10px'}},
				{ key: 'title', label: 'Task', sortable: true , thStyle:  {width: '9%',fontSize:'10px'}},
				{ key: 'feedback', label: 'Feedback From', sortable: false , thStyle:  {width: '5%',fontSize:'10px'}},
				{ key: 'start', label: 'Start Date', sortable: true , thStyle:  {width: '5%',fontSize:'10px'}},
				{ key: 'task_status', label: 'Progress Status', sortable: true , thStyle:  {width: '6%',fontSize:'10px'}},
				{ key: 'crew', label: 'Crew', sortable: true , thStyle:  {width: '8%',fontSize:'10px'}},
				{ key: 'completed_by', label: 'Completed By', sortable: false , thStyle:  {width: '7%',fontSize:'10px'}},
				{ key: 'end', label: 'End Date', sortable: true , thStyle:  {width: '5%',fontSize:'10px'}},
				{ key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '7%',fontSize:'10px'}},
				{ key: 'actual_started', label: 'Actual Time', sortable: true , thStyle:  {width: '6%',fontSize:'10px'}},
            	{ key: 'actual_end', label: 'Completed Time', sortable: true , thStyle:  {width: '6%',fontSize:'10px'}},
            	{ key: 'total_time', label: 'Time Taken', sortable: true , thStyle:  {width: '6%',fontSize:'10px'}},
				{ key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '11%',fontSize:'10px'}} */
			
				{ key: 'checkbox', label:'',sortable: false , thStyle:  {width: '5%',fontSize:'10px'}},
                { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '4%',fontSize:'10px'}},
				{ key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '17%',fontSize:'10px'}},
				
				{ key: 'title', label: 'Task', sortable: true , thStyle:  {width: '25%',fontSize:'10px'}},
				
				{ key: 'start', label: 'Start Date', sortable: true , thStyle:  {width: '12%',fontSize:'10px'}},
				{ key: 'end', label: 'End Date', sortable: true , thStyle:  {width: '12%',fontSize:'10px'}},
				
                { key: 'task_status', label: 'Progress Status', sortable: true , thStyle:  {width: '10%',fontSize:'10px'}},
				
				{ key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%',fontSize:'10px'}}
			],

			items:[],
			isSortDirDesc: true,
			perPageOptions: [10, 20, 50, 100],
			perPage : 10,
			sortBy : 'updated_at',
			webUrl: process.env.VUE_APP_SERVER_URL,
			totalRecords : 0,
			currentPage : 1,
			searchQuery:'',
			from:null,
			to:null,
			selectAll:false,
			selected:[],
			crew:[],
			yearData:[],
			sites:[],
			popshowDismissibleAlert:false,
			error_message:'',
      allAssignCrews:[],

			current_date: moment(new Date()).tz('Asia/Singapore').format('YYYY-MM-DD'),
      id:null,
      remarks:"",

      redo_comment: '',
		}
	},

	methods : {
    openRedoModel(){
			this.popshowDismissibleAlert = false;
			this.redo_comment = '';
			this.$refs['redo-modal'].show();
		},

    redoTasks(bvModalEvt){
			bvModalEvt.preventDefault();
			if (this.selected.length > 0) {
				return this.$store.dispatch(POST_API, {
					data:{
						comment:this.redo_comment,
						ids:this.selected,
					},
					api: '/api/add-multiple-redo-comments-defect-request'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.popshowDismissibleAlert = true;
					} else {
						this.popshowDismissibleAlert = false;
						var message  = this.$store.getters.getResults.message;
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: message,
							showConfirmButton: false,
							timer: 1500
						}).then((result) => {
							// this.filterTable();
							this.$refs['redo-modal'].hide();
							this.selected = [];
              this.$emit('updateStatus', 'failed');
						});						
					}
				});
			} else {
				Swal.fire({
					position: 'center',
					icon: 'warning',
					title: 'Please select tasks.',
					showConfirmButton: false,
					timer: 1500
				})
			}
		},

    reGenerateReport(id){
        return this.$store.dispatch(POST_API, {
            data:{ id:id },
            api: '/api/regenerate-defect-request-report'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                
                var data  = this.$store.getters.getResults.data;
                
                Swal.fire({
                    icon: 'success',
                    title: 'Report Regenerated Successfully.',
                    showConfirmButton: false,
                    timer: 1500
                })
                .then( () => {
                    window.open(data + '?' + new Date(), '_blank');
                    this.filterTable();  
                })
            }
        });
    },
    
		updateTask(status,task){
        var txt = "";
        if (status == 'in_progress') {
            txt = 'start';
        }else {
            txt = 'restart';
        }
        var msg = 'Are you sure want to '+txt+' this task?';
        
        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {

                return this.$store.dispatch(POST_API, {
                    data:{
                        status:status,
                        id:task._id,
                        no_photo_reporting:task.site.no_photo_reporting
                    },
                    api: '/api/app-update-defect-request-status'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.errorAlert();              
                    } else {
                        this.popshowDismissibleAlert = false;

                        Swal.fire({
                            icon: 'success',
                            title: 'Defect Request Updated Successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        .then((result) => {

                            if (status == 'in_progress') {
                                this.$emit('updateStatus',status);   
                            }else if(status == 'open'){
                                this.$emit('updateStatus',status);   
                            }

                            this.filterTable();
                        });
                    }
                });
            }
        })
        .catch(err => {})
    },

		openPdf(path){
        window.open(path+'?'+new Date(), '_blank');
    },

		openFailedPopup(data){
        this.id = data;
        this.remarks = "";
        this.$refs['failed_tasks'].show();
    },

		markTaskFailed(e){
          e.preventDefault();

          if (this.remarks == "") {
              this.error_message = "Please enter reason";
              this.popshowDismissibleAlert = true;
          }else{

              return this.$store.dispatch(POST_API, {
                  data:{
                      reason_for_yes:this.remarks,
                      reason_for_no:this.remarks,
                      status:'failed',
                      id:this.id._id,
                      no_photo_reporting: this.id.site.no_photo_reporting
                  },
                  api: '/api/mark-multiple-failed-defect-request'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.popshowDismissibleAlert = true;
                  } else {
                      this.popshowDismissibleAlert = false;
                      var message  = this.$store.getters.getResults.message;
                      
                      Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: message,
                          showConfirmButton: false,
                          timer: 1500
                      }).then((result) => {
                          this.filterTable();
                          this.$refs['failed_tasks'].hide();
                          this.$emit('updateStatus', 'failed');
                      });                
                  }
              });

          }
      },

      getTableColumns(){
        /* if (this.tab_status == 'open' || this.tab_status == 'failed') {
            
            return  [
                { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '3%',fontSize:'11px'}},
                { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '3%',fontSize:'11px'}},
                { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '23%',fontSize:'11px'}},
                
                { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '40%',fontSize:'11px'}},
                
                { key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '16%',fontSize:'11px'}},
                
                { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%',fontSize:'11px'}}

            ];
        }else if (this.tab_status == 'in_progress') {
            
            return  [
                { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '3%',fontSize:'11px'}},
                { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '3%',fontSize:'11px'}},
                { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '20%',fontSize:'11px'}},
                
                { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '27%',fontSize:'11px'}},
                
                { key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '16%',fontSize:'11px'}},

                { key: 'actual_started', label: 'Start Time', sortable: true , thStyle:  {width: '16%',fontSize:'11px'}},
                
                { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%',fontSize:'11px'}}

            ];
        }else if (this.tab_status == 'validate') { */
            
          if (this.tab_status == 'all') {
            return  [
                { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%',fontSize:'11px'}},
                { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '1%',fontSize:'11px'}},
                { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '20%',fontSize:'11px'}},
                
                { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '20%',fontSize:'11px'}},
                
                { key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '16%',fontSize:'11px'}},

                // { key: 'actual_started', label: 'Start Time', sortable: true , thStyle:  {width: '13%',fontSize:'11px'}},

                { key: 'actual_end', label: 'Completed Time', sortable: true , thStyle:  {width: '15%',fontSize:'11px'}},

                { key: 'task_status', label: 'Status', sortable: true , thStyle:  {width: '12%',fontSize:'11px'}},
                
                { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%',fontSize:'11px'}}
            ];
        } else if (this.tab_status == 'open') {
            return  [
                { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%',fontSize:'11px'}},
                { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '1%',fontSize:'11px'}},
                { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '16%',fontSize:'11px'}},
                
                { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '18%',fontSize:'11px'}},
                
                { key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},

                // { key: 'actual_started', label: 'Start Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},

                { key: 'actual_end', label: 'Completed Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},
                
                { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '22%',fontSize:'11px'}}
            ];
        } else {                
            return  [
                { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%',fontSize:'11px'}},
                { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '1%',fontSize:'11px'}},
                { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '25%',fontSize:'11px'}},
                
                { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '22%',fontSize:'11px'}},
                
                { key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '18%',fontSize:'11px'}},

                // { key: 'actual_started', label: 'Start Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},

                { key: 'actual_end', label: 'Completed Time', sortable: true , thStyle:  {width: '18%',fontSize:'11px'}},
                
                { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%',fontSize:'11px'}}
            ];
        }
        /* }else if (this.tab_status == 'all') {
            
            return  [
                { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '3%',fontSize:'11px'}},
                { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '3%',fontSize:'11px'}},
                { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '22%',fontSize:'11px'}},
                
                { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '27%',fontSize:'11px'}},
                
                { key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '16%',fontSize:'11px'}},

                { key: 'task_status', label: 'Status', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},
                
                { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%',fontSize:'11px'}}

            ];
        } */
    },

		updateDateFormat(){
			if (this.siteData != '') {
				this.sites.forEach(item => {
					if (item._id == this.siteData) {
						this.date_format = item.pdf_date_format;
						this.time_format = item.pdf_time_format; 
					}
				})
			}else{
				this.date_format = 'DD MMM YYYY';
				this.time_format = 'HH:mm';
			}
		},

		dataList(){
			return this.$store.dispatch(POST_API, {
				data:{
					page   	      : this.currentPage,
					keyword	      : this.searchQuery,
					rowsPerPage   : this.perPage,
					sortBy 	      : this.sortBy,
					sortDirection : this.isSortDirDesc,
					role   	      : this.$store.getters.currentUser.role,
					om_sites      : this.$store.getters.currentUser.om_sites,
					site   	      : this.siteData ? this.siteData._id : null,
					locations     : this.locations,
					crew   	      : this.crew_filter,
					start	      : moment(this.start).format('YYYY-MM-DD'),
					end    	      : moment(this.end).format('YYYY-MM-DD'),
					status 	      : this.status,
					tab_status    : this.tab_status,
				},
				api: '/api/defect-list-modal'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
				} else {
					this.items        = this.$store.getters.getResults.data.docs;
					this.totalRecords = this.$store.getters.getResults.data.total;
					this.perPage      = this.$store.getters.getResults.data.limit;
					this.from         = this.$store.getters.getResults.data.from;
					this.to           = this.$store.getters.getResults.data.to;
                    
                    var data = {
                        open : this.$store.getters.getResults.data.open,
                        in_progress : this.$store.getters.getResults.data.in_progress,
                        validate : this.$store.getters.getResults.data.validate,
                        failed : this.$store.getters.getResults.data.failed
                    }

                    this.$emit('updateCount',data);

					return this.items;
				}
			});
		},

		filterTable(){
            if (typeof this.$refs['refUserListTable'] != 'undefined') {
                this.$refs.refUserListTable.refresh();
            }
			this.selected = [];
			this.selectAll = false;
		},

		variantColor(status){
			if (status == 'active') {
				return 'success';
			}else{
				return 'primary';
			}
		},

		variantProgressColor(status){
			if (status == 'open') {
				return 'primary';
			}else if(status == 'in_progress'){
				return 'warning'
			}else if(status == 'validate'){
				return 'info'
			}else if(status == 'failed'){
				return 'danger'
			}else{
				return 'success';
			}
		},

        selectall(e){
			this.selected = [];		
			if (e) {
				var selected = [];

				this.items.forEach(function(item){
					selected.push(item._id);
				})
				this.selected = selected;
			}
		},

        /* view(items){
			var keyword = this.searchQuery != '' ? this.searchQuery : 'null';      
			var sortBy = this.sortBy;
			var sortDirection = this.isSortDirDesc == false ? 'false':'true';
			var temp_location = [];
			if(this.locations.length > 0){
				this.locations.forEach(el => {
					temp_location.push(el._id)
				})
			}
			var site       = this.siteData ? this.siteData._id : "null";
			var location   = temp_location;
			var crew       = this.crew_filter ? this.crew_filter : "null";
			var status     = 'null';
			var tab_status = this.tab_status;
			var start      = this.start != '' ? moment(this.start).format('YYYY-MM-DD'):'null';
			var end        = this.end != '' ? moment(this.end).format('YYYY-MM-DD'):'null';
			
			var filter = {
				id            : items.item._id,
				keyword       : keyword,
				sortBy        : sortBy,
				sortDirection : sortDirection,
				site          : site,
				location      : location.length > 0 ? location.toString() : 'null',
				crew          : crew,
				status        : status,
				tab_status    : tab_status,
				start         : start,
				end           : end
			};
			this.$router.push({ name: 'view-defect-status', params: filter });
		}, */

		view(items){
			var temp_location = [];
			if(this.locations.length > 0){
				this.locations.forEach(el => {
					temp_location.push(el._id)
				})
			}
			var site       = this.siteData ? this.siteData._id : "null";
			var location   = temp_location;
			var crew       = this.crew_filter ? this.crew_filter : "null";
			var start      = this.start != '' ? moment(this.start).format('YYYY-MM-DD'):'null';
			var end        = this.end != '' ? moment(this.end).format('YYYY-MM-DD'):'null';
			var tab_status = this.tab_status;
			
			var filter = {
				id            : items.item._id,
				site          : site,
				location      : location.length > 0 ? location.toString() : 'null',
				crew          : crew,
				start         : start,
				end           : end,
				tab_status    : tab_status
			};
			this.$router.push({ name: 'view-defect-status', params: filter });
		},

        deleteItem(id){
			var msg = 'Are you sure want to delete this record?';
			var message='Defect Request Deleted Successfully.';

			Swal.fire({
				title: 'Please Confirm',
				text: msg,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			})
			.then(result => {
				if (result.value) {
					return this.$store.dispatch(POST_API, {
						data:{
							id: id,
							status:'deleted'
						},
						api:"/api/change-defect-request-status",
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;
							this.showAlert();
						} else {                    
							Swal.fire({
								icon: 'success',
								title: message,
								showConfirmButton: false,
								timer: 1500
							})                    
							this.$refs.refUserListTable.refresh();
						}
					});
				}
			})
			.catch(err => {})
		},

        openAssignCrewModel(){
			this.popshowDismissibleAlert = false;
			this.crew = [];
			this.siteCrew(this.siteData._id);
			this.$refs['assign_crew'].show();
		},

        closeTask(){
			if (this.selected.length > 0) {			
				if (this.$store.getters.currentUser.default_signature == null) {
					this.openSignatureBox();
				}else {
					var data = {
						image:this.$store.getters.currentUser.default_signature,
						name:'',
						type:'client',
						default:'yes'
					}
					this.markClosedTask(data);
				}
			} else {
				Swal.fire({
					icon: 'warning',
					title: 'Please select tasks.',
					showConfirmButton: false,
					timer: 1500
				})
			}
		},
        markClosedTask(data){
			return this.$store.dispatch(POST_API, {
				data:{
					signature:data,
					ids:this.selected,
					from:'web'
				},
				api: '/api/close-multiple-defect-request'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					Swal.fire({
						icon: 'warning',
						title: this.error_message,
						showConfirmButton: false,
						timer: 1500
					})                
				} else {
					this.popshowDismissibleAlert = false;
					Swal.fire({
						icon: 'success',
						title: 'Defect Request Closed Successfully.',
						showConfirmButton: false,
						timer: 1500
					}).then((result) => {
						// this.filterTable();
            this.$emit('updateStatus', 'closed');
					});
				}
			});
		},

        changeMultipleStatus(status){
			if (this.selected.length > 0) {
				var msg = '';
				var msg2 = '';

				if (status == 'deleted') {
					msg = 'Are you sure you want to delete selected record?';
					msg2 = 'Records Deleted Successfully.';
				}
				if (status == 'active') {
					msg = 'Are you sure you want to Activate selected record?';
					msg2 = 'Records Activated Successfully.';
				}
				if (status == 'inactive') {
					msg = 'Are you sure you want to inactive selected record?';
					msg2 = 'Records Inactivated Successfully.';
				}
			
				Swal.fire({
					title: 'Please Confirm',
					text: msg,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					cancelButtonText: 'No'
				})
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								id: this.selected,
								status:status
							},
							api:"/api/change-multiple-defect-request-status-status",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {							
								Swal.fire({
									icon: 'success',
									title: msg2,
									showConfirmButton: false,
									timer: 1500
								})							
								this.selected = [];
								this.selectAll = false;				
								this.$refs.refUserListTable.refresh();
							}
						});
					}
				}).catch(err => { })
			}else{
				Swal.fire({
					icon: 'warning',
					title: 'Please select tasks.',
					showConfirmButton: false,
					timer: 1500
				})
			}
		},

        siteCrew(site){
			return this.$store.dispatch(POST_API, {
				data:{
					site:site,
					role: [/* 'site_manager', 'site_client' */'crew']
				},
				api: '/api/all-crew-by-role'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					this.allCrews = data;
					this.allAssignCrews = [];

					var obj = {
						_id:'unassign',
						full_name:'Unassign'
					}

					this.allAssignCrews.push(obj);

					this.allCrews.forEach(item => {
						this.allAssignCrews.push(item);
					})

					return this.allCrews;
				}
			});
		},

        updateCrew(bvModalEvt){
			bvModalEvt.preventDefault()
					
			if (this.selected.length > 0) {
				return this.$store.dispatch(POST_API, {
					data:{
						crew:this.crew,
						ids:this.selected,
					},
					api: '/api/update-defect-request-crews'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.popshowDismissibleAlert = true;
					} else {
						this.popshowDismissibleAlert = false;
						var message  = this.$store.getters.getResults.message;
						
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: message,
							showConfirmButton: false,
							timer: 1500
						}).then((result) => {
							this.filterTable();
							this.$refs['assign_crew'].hide();
							this.selected = [];
						});                
					}
				});
			}else{
				Swal.fire({
					position: 'center',
					icon: 'warning',
					title: 'Please select tasks.',
					showConfirmButton: false,
					timer: 1500
				})
			}      
		},
	},

    mounted(){
        Bus.$on('refreshTable', () => {
            this.filterTable();
        })
    }
  
}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
	.wordBreak2{
		text-align: justify !important;
		text-justify: inter-word !important;
	}
</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>